@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 5px;
    background: #ffff ;
}

::-webkit-scrollbar-track {
    background: #ffff

}

::-webkit-scrollbar-thumb {
    background: #be185d;
    height: 64px;
}